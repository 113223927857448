/* eslint-disable */
export default class WebSocketClient
{
    constructor( core, scope, websocketUrl, userId )
    {

        this.config = core.getConfig()
        this.eventManager = core.getEventManager()
        this.uuid = core.getUuid()
        this.store = core.getStore()
        this.logger = core.getLogger()
        this.timer = core.getCoreTimer()
        this.crypto = core.getCrypto()

        this.userId = userId
        this.url = websocketUrl
        this.scope = core.f().ucFirst( scope )

        this.logSign = 'WebSocketClient::' + this.scope + '::'

        this.lastTick = 0
        this.tickCount = 0
        this.halt = false

        this.store.commit( 'set' + this.scope + 'Connected', false )
        this.store.commit( 'set' + this.scope + 'Authorized', false )

        this.timeout = 30000
        this.recovery = 3000

        this.pingTimer = setInterval( () =>
        {
            this.ping()
        }, ( 10000 ) )

        this.connected = false
        this.processingState = false

        this.queue = {}

        this.connectedBefore = false
        this.setup()

        return this

    }

    processVerifyTokenResponse( response, sessionId )
    {

        return new Promise( ( resolve, reject ) =>
        {

            if( true === response.state )
            {

                if( undefined !== response.data
                    && undefined !== response.data.verifyToken
                    && null !== response.data.verifyToken )
                {

                    let tokenResult = this.crypto.decrypt( response.data.verifyToken ),
                        serverToken = 'Local' === this.scope ? this.crypto.encrypt( tokenResult ) : this.crypto.encryptForServer( tokenResult )

                    if( false !== serverToken )
                    {

                        let message = {
                            method: 'session.tokenVerification',
                            scope : ( 'Local' === this.scope ? 'local' : '' ) + 'client',
                            token : serverToken
                        }

                        if( undefined !== sessionId )
                        {
                            message.sessionId = sessionId
                        }

                        this.request( message )
                            .then( tokenResult =>
                            {

                                if( null !== tokenResult.data.sessionId )
                                {

                                    this.store.commit( 'set' + this.scope + 'SessionId', tokenResult.data.sessionId )
                                    this.store.commit( 'set' + this.scope + 'Authorized', true )
                                    return resolve()

                                }

                            } )
                            .catch( () =>
                            {
                                this.store.commit( 'set' + this.scope + 'Authorized', false )
                                return reject()
                            } )

                    }

                }

            }
            else
            {
                this.store.commit( 'set' + this.scope + 'Authorized', false )
                return reject()
            }


        } )

    }

    authorize()
    {
        return new Promise( ( resolve, reject ) =>
        {

            let message = {
                method: 'session.authorize',
                scope : ( 'Local' === this.scope ? 'local' : '' ) + 'client',
                serial: this.userId
            }

            this.request( message )
                .then( response =>
                {

                    this.processVerifyTokenResponse( response )
                        .then( () =>
                        {
                            return resolve()
                        } )
                        .catch( () =>
                        {
                            return reject( 'token verification failed' )
                        } )

                } )
                .catch( error =>
                {
                    return reject( error )
                } )

        } )

    }

    request( requestMessage, timeout, prepared )
    {

        return new Promise( ( resolve, reject ) =>
        {

            if( window.forceOffline )
            {
                this.connected = false
                this.store.commit( 'setOnline', false )
                return reject()
            }
            else
            {
                this.connected = null !== this.client && undefined !== this.client && this.client.readyState === 1
            }

            if( this.connected
                && !this.processingState
                && null !== this.client
                && 1 === this.client.readyState )
            {

                return resolve( this.performRequest( requestMessage, timeout, prepared ) )

            }
            else
            {
                if( null !== this.client
                    && 3 === this.client.readyState )
                {
                    this.reset()
                }
                return reject( 'ERR_NOT_CONNECTED' )
            }

        } )

    }

    performRequest( requestMessage, timeout, prepared )
    {

        return new Promise( resolve =>
        {

            if( undefined === prepared )
            {
                requestMessage.sessionId = this.store.getters[ this.scope.toLowerCase() + 'SessionId' ]
                requestMessage.appVersion = this.config.version
            }

            let messageUuid = this.uuid.generate()
            requestMessage.messageId = messageUuid

            let message = JSON.stringify( requestMessage )
            this.queue[ messageUuid ] = requestMessage
            this.eventManager.add( 'onSocketMessage-' + messageUuid, ( response ) =>
            {

                this.timer.removeTimeout( 'socket-timeout-' + messageUuid )
                this.eventManager.remove( 'onSocketTimeout-' + messageUuid )
                return resolve( this.handleResponse( response ) )

            } )

            this.eventManager.add( 'onSocketTimeout-' + messageUuid, ( response ) =>
            {

                this.logger.cdump( 'WebSocketClient.request', 'message was', this.queue[ messageUuid ] )
                this.logger.cerror( 'WebSocketClient.request', 'timeout for message ' + messageUuid + ' after ' + ( timeout !== undefined ? timeout : this.timeout ) + ' millis for ', JSON.stringify( this.queue[ messageUuid ] ) )
                this.eventManager.remove( 'onSocketMessage-' + messageUuid )
                return resolve( this.handleResponse( response ) )

            } )

            this.timer.addTimeout( 'socket-timeout-' + messageUuid,
                ( timeout !== undefined ? timeout : this.timeout ),
                () =>
                {
                    this.eventManager.dispatchAndRemove( 'onSocketTimeout-' + messageUuid, { state: false } )
                } )

            this.client.send( message )

        } )

    }

    handleMessage( message, plain )
    {

        let payload = plain ? message : JSON.parse( message.data )

        if( plain )
        {
            return payload
        }
        else
        {
            this.eventManager.dispatchAndRemove( 'onSocketMessage-' + payload.messageId, payload )
        }

    }

    handleResponse( response )
    {

        return new Promise( resolve =>
        {

            return resolve( this.handleMessage( response, true ) )

        } )

    }

    authorizeDevice()
    {
        this.authorize()
            .then( () =>
            {
                this.logger.clog( this.logSign + 'authorizeDevice', 'authorization success.' )
            } )
            .catch( e =>
            {
                this.logger.cerror( this.logSign + 'authorizeDevice', 'authorization failed:', e )
            } )
    }

    trySessionRecover()
    {
        return new Promise( ( resolve, reject ) =>
        {

            let message = {
                method   : 'session.recover',
                scope    : ( 'Local' === this.scope ? 'local' : '' ) + 'client',
                serial   : this.userId,
                sessionId: this.store.getters[ this.scope.toLowerCase() + 'SessionId' ]
            }

            this.request( message )
                .then( response =>
                {
                    if( true === response.state )
                    {
                        this.processVerifyTokenResponse( response, this.store.getters[ this.scope.toLowerCase() + 'SessionId' ] )
                        return resolve()
                    }
                    else
                    {
                        this.store.commit( 'set' + this.scope + 'SessionId', undefined )
                        return reject()
                    }

                } )
                .catch( e =>
                {
                    return reject( e )
                } )

        } )
    }

    command( command )
    {
        return new Promise( ( resolve, reject ) =>
        {

            let message = {
                method   : 'endpoint.command',
                scope    : this.scope.toLowerCase() + 'client',
                command  : command,
                sessionId: this.store.getters[ this.scope.toLowerCase() + 'SessionId' ]
            }

            this.eventManager.dispatch( 'on-device-refresh-pause', true )

            this.request( message )
                .then( () =>
                {
                    this.eventManager.dispatch( 'on-device-refresh-pause', false )
                } )
                .catch( e =>
                {
                    this.eventManager.dispatch( 'on-device-refresh-pause', false )
                    console.log( 'E', e )
                } )

        } )
    }

    reset()
    {

        this.logger.clog( this.logSign + 'reset', 'resetting websocket...' )
        this.store.commit( 'set' + this.scope + 'Connected', false )
        this.store.commit( 'set' + this.scope + 'Authorized', false )
        this.eventManager.dispatch( 'on-connection-state-change' )
        delete this.client
        setTimeout( () =>
        {
            this.logger.clog( this.logSign + 'reset', 'startover...' )
            this.setup()
        }, 3000 )

    }

    ping()
    {
        let message = {
            method   : 'session.ping',
            scope    : 'client',
            sessionId: this.store.getters[ this.scope.toLowerCase() + 'SessionId' ],
            serial   : this.userId,
        }
        this.request( message )
            .then( response => {

                if( false === response.state )
                {
                    this.defaultLogin()
                }

            })
    }

    defaultLogin()
    {
        this.trySessionRecover()
            .then( () =>
            {
                this.logger.clog( this.logSign + 'setup', 'session recovery success.' )
                this.eventManager.dispatch( 'on-connection-state-change' )
            } )
            .catch( () =>
            {
                this.authorizeDevice()
                this.eventManager.dispatch( 'on-connection-state-change' )
            } )
    }

    setup()
    {

        this.client = new WebSocket( this.url, 'json' )
        this.logger.clog( this.logSign + 'setup', 'opening connection' )

        this.client.onmessage = ( message =>
        {
            this.handleMessage( message )
            this.eventManager.dispatch( 'on-connection-state-change' )
        } )
        this.client.onopen = ( () =>
        {

            this.eventManager.dispatch( 'on-connection-state-change' )
            this.logger.clog( this.logSign + 'setup', 'connected' )
            this.connectedBefore = true
            this.store.commit( 'set' + this.scope + 'Connected', true )

            if( false !== this.store.getters[ this.scope.toLowerCase() + 'SessionId' ] )
            {
                this.defaultLogin()
            }
            else
            {
                this.authorizeDevice()
                this.eventManager.dispatch( 'on-connection-state-change' )
            }

        } )
        this.client.onclose = ( ( closeEvent ) =>
        {
            this.logger.clog( this.logSign + 'reset', 'closed connection' )
            this.reset()
        } )
        this.client.onerror = ( error =>
        {
            this.logger.cerror( this.logSign + 'reset', 'connection error: ' + error )
            this.reset()
        } )

    }

}