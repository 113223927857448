import Logger       from "@/classes/Logger";
import Config       from "@/classes/Config";
import Database     from "@/classes/Database";
import UUID         from "@/classes/UUID";
import EventManager from "@/classes/EventManager";
import Crypto       from "@/classes/Crypto";
import KeyRing      from "@/classes/KeyRing";
import CoreTimer    from "@/classes/CoreTimer";
import WebSockets   from "@/classes/WebSockets";
import Functions    from "@/classes/Functions";
import Ui           from "@/classes/Ui";
import Devices      from "@/classes/Devices";
import Sorter       from "@/classes/Sorter";
import { reactive } from "vue";

export default class Core
{

    constructor( store, router )
    {

        if( !Core.instance )
        {

            this.config = new Config()
            this.functions = new Functions()
            this.uuid = new UUID()
            this.store = store
            this.router = router
            this.crypto = new Crypto()
            this.logger = new Logger( this.config )
            this.eventManager = new EventManager( this )
            this.coreTimer = new CoreTimer( this )
            this.database = new Database( this )
            this.store.commit( 'setDatabase', this.database )
            this.store.dispatch( 'initStates' )
            this.keyRing = new KeyRing( this )
            this.webSockets = new WebSockets( this )
            this.ui = new Ui( this )
            this.sorter = new Sorter()

            this.states = new Map()
            this.reactiveStates = new reactive( {
                sortingActive: false
            } )

            this.devices = new Devices( this )

            this.logger.log( 'initialized custom smarthome core' )

            Core.instance = this

        }

        return Core.instance

    }

    setState( key, value )
    {
        this.states.set( key, value )
    }

    getState( key )
    {
        return this.states.get( key )
    }

    setRState( key, value )
    {
        this.reactiveStates[key] = value
    }

    getRState( key )
    {
        return this.reactiveStates[key]
    }

    f = () =>
    {
        return this.functions
    }

    getClient = () =>
    {
        return this.webSockets
    }

    getConfig = () =>
    {
        return this.config
    }

    getCoreTimer = () =>
    {
        return this.coreTimer
    }

    getCrypto = () =>
    {
        return this.crypto
    }

    getDatabase = () =>
    {
        return this.database
    }

    getDevices = () =>
    {
        return this.devices
    }

    getEventManager = () =>
    {
        return this.eventManager
    }

    getKeyRing = () =>
    {
        return this.keyRing
    }

    getLogger = () =>
    {
        return this.logger
    }

    getRouter = () =>
    {
        return this.router
    }

    getSorter = () =>
    {
        return this.sorter
    }

    getStore = () =>
    {
        return this.store
    }

    getUi = () =>
    {
        return this.ui
    }

    getUuid = () =>
    {
        return this.uuid
    }

}
