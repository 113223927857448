function _isSortable( what )
{

    if( true === what
        || false === what
        || undefined === what
        || null === what
        || ( !isNaN( parseFloat( what ) ) && isFinite( what ) ) )
    {
        return false
    }

    return true

}

function _sortDefaultAsc( a, b )
{

    let keyA = a.__sortId
    let keyB = b.__sortId

    if( !_isSortable( keyA ) || !_isSortable( keyB ) )
    {
        if( keyA < keyB ) return -1
        if( keyA > keyB ) return 1
        return 0
    }

    return keyA.localeCompare( keyB, undefined, { numeric: true, sensitivity: 'base' } )

}

function _sortDefaultDesc( a, b )
{

    let keyA = a.__sortId
    let keyB = b.__sortId

    if( !_isSortable( keyA ) || !_isSortable( keyB ) )
    {
        if( keyA > keyB ) return -1
        if( keyA < keyB ) return 1
        return 0
    }

    return keyB.localeCompare( keyA, undefined, { numeric: true, sensitivity: 'base' } )

}

export default class Sorter
{

    constructor()
    {

        if( !Sorter.instance )
        {

            Sorter.instance = this

        }

        return Sorter.instance

    }

    sortId( field )
    {

        let sortId = 0

        if( !isNaN( parseInt( field ) )
            && '' + parseInt( field ) === '' + field )
        {
            return parseInt( field )
        }

        if( undefined === field )
        {
            return sortId
        }

        sortId = '' + field
        return sortId

    }

    sortObjects( sortable, sortby, direction )
    {

        let temp = []
        let sorted = []

        for( let n in sortable )
        {
            if( -1 < sortby.indexOf( '.' ) )
            {
                let t = sortby.split( '.' )
                temp.push( {
                    __sortId: sortable[ n ][ t[ 0 ] ][ t[ 1 ] ],
                    original: sortable[ n ]
                } )
            }
            else
            {
                let sortId = sortable[ n ][ sortby ]
                temp.push( {
                    __sortId: sortId,
                    original: sortable[ n ]
                } )
            }
        }

        if( 'ascending' == direction
            || undefined === direction )
        {
            temp.sort( _sortDefaultAsc )
        }
        else
        {
            temp.sort( _sortDefaultDesc )
        }

        for( let n in temp )
        {
            sorted.push( temp[ n ].original )
        }

        return sorted

    }

    multiSortObjects( sortable, sortby, ignorePinning, inline )
    {

        if( undefined === sortable )
        {
            return
        }

        if( undefined === sortby )
        {
            return this.sortObjects( sortable, 'timestamp', 'ascending' )
        }

        let pinningSorted = false

        for( let i = 0; i < sortby.length; i++ )
        {

            let s         = sortby[ i ],
                sort      = s[ 0 ],
                direction = s[ 1 ]

            if( sort === 'pinned' )
            {
                pinningSorted = true
            }

            for( let n in sortable )
            {

                if( undefined !== sortable[ n ] )
                {

                    let sortId = this.sortId( sortable[ n ][ sort ] )

                    if( undefined === sortable[ n ].pinned )
                    {
                        sortable[ n ].pinned = false
                    }
                    if( undefined === sortable[ n ][ sort ]
                        || ( 'timestamp' === sort && undefined !== sortable[ n ][ 'tsmpEnd' ] ) )
                    {
                        if( 'update' === sort
                            && undefined !== sortable[ n ][ 'timestamp' ] )
                        {
                            sortId = sortable[ n ][ 'timestamp' ]
                        }
                        else
                        {
                            if( 'timestamp' === sort
                                && undefined !== sortable[ n ][ 'tsmpEnd' ] )
                            {
                                sortId = sortable[ n ][ 'tsmpEnd' ]
                            }
                            else
                            {
                                if( 'update' === sort
                                    && undefined !== sortable[ n ][ 'lists' ] )
                                {

                                    let minUpdate = undefined !== sortable[ n ][ 'tsmpEnd' ] ? sortable[ n ][ 'tsmpEnd' ] : -1
                                    for( let l in sortable[ n ][ 'lists' ] )
                                    {
                                        let list = sortable[ n ][ 'lists' ][ l ]
                                        if( list.update > minUpdate )
                                        {
                                            minUpdate = list.update
                                        }
                                    }
                                    sortId = minUpdate
                                }
                                else
                                {
                                    sortId = '0'
                                }
                            }
                        }

                    }

                    if( sort == 'sortableTimestamp' )
                    {
                        sortId = sortId.replace( '.', '' )
                        if( sortId.length < 6 )
                        {
                            sortId = sortId.substring( 0, 4 ) + '0' + sortId.substring( 4, 1 )
                        }
                        sortId = parseInt( sortId )
                    }

                    if( sort == 'color' )
                    {
                        sortId = this.getColorSortId( sortable[ n ][ sort ] )
                    }

                    if( sort == 'done' )
                    {
                        sortId = sortable[ n ][ sort ] === true || sortable[ n ][ sort ] === 1 ? 'A' : 'Z'
                    }

                    sortable[ n ].__sortId = sortId

                }

            }

            if( 'ascending' == direction
                || undefined === direction )
            {
                sortable.sort( _sortDefaultAsc )
            }
            else
            {
                sortable.sort( _sortDefaultDesc )
            }

            if( !pinningSorted && true !== ignorePinning )
            {
                this.sortObjects( sortable, 'pinned', 'descending' )
            }

        }

        if( !inline )
        {
            return sortable
        }

    }

}