<template>
    <div v-if="active"
         class="fullscreen-controls">
        <template v-if="'Shutter' === parsed.deviceType">
            <ShutterControl :device="device" @close="handleClose"/>
        </template>
        <template v-if="'GateControl' === parsed.deviceType">
            <GateControl :device="device" @close="handleClose"/>
        </template>
        <template v-if="'HueDevice' === parsed.deviceType">
            <HueControl :device="device" @close="handleClose"/>
        </template>
    </div>
</template>

<script>
import ShutterControl from "@/components/routes/parts/devices/ShutterControl";
import GateControl    from "@/components/routes/parts/devices/GateControl";
import HueControl     from "@/components/routes/parts/devices/HueControl.vue";
export default {
    name: "FullScreenControls",
    components: { HueControl, GateControl, ShutterControl },
    data()
    {
        return {
            active: false,
            device: false,
            parsed: false
        }
    },

    created()
    {
        this.$core.getEventManager()
            .add( 'fullscreen-controls', ( setup ) =>
            {
                this.active = true
                this.device = setup.device
                this.parsed = setup.parsed
            } )
    },

    beforeUnmount()
    {
        this.$core.getEventManager()
            .remove( 'fullscreen-controls' )
    },

    methods: {
        handleClose()
        {
            this.active = false
            this.device = false
            this.parsed = false
        }
    }
}
</script>