<template>
    <div class="roomselector-roomicon" :style="'background-image:url('+room.background+')'">
        <div class="label">{{ room.name }}</div>
    </div>
</template>

<script>
export default {
    name : "RoomIcon",
    props: {
        room: { Type: Object, required: true }
    }
}
</script>