<template>
    <nav id="menu" class="light-shadowed" @click="forceChange">
        <input type="checkbox"
               href="#"
               class="menu-open"
               name="menu-open"
               id="menu-open"
               @change="handleChange"/>
        <label :class="'menu-open-button'+menuOpenClass" for="menu-open">
            <span class="hamburger hamburger-1"></span>
            <span class="hamburger hamburger-2"></span>
            <span class="hamburger hamburger-3"></span>
        </label>
        <a v-for="filler in getFillers()" class="menu-item clear" v-bind:key="'navigation_filler_'+filler.key"></a>
        <a v-for="route in routes"
           v-bind:key="'navigation_key_'+route.name"
           :id="'menu-item-'+route.name"
           @click="navigate( route.name )"
           :class="'menu-item menu-icon menu-'+route.class"
           :alt="route.title" :title="route.title">
            <span class="menu-item-span" v-html="route.caption"/>
        </a>
    </nav>

    <!-- filters -->
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
            <filter id="shadowed-goo">
                <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10"/>
                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 25 -7"
                               result="goo"/>
                <feGaussianBlur in="goo" stdDeviation="3" result="shadow"/>
                <feColorMatrix in="shadow" mode="matrix" values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 1 -0.2"
                               result="shadow"/>
                <feOffset in="shadow" dx="1" dy="1" result="shadow"/>
                <feBlend in2="shadow" in="goo" result="goo"/>
                <feBlend in2="goo" in="SourceGraphic" result="mix"/>
            </filter>
            <filter id="goo">
                <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10"/>
                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                               result="goo"/>
                <feBlend in2="goo" in="SourceGraphic" result="mix"/>
            </filter>
        </defs>
    </svg>

</template>

<script>
export default {

    name: "Navigation",
    data()
    {
        return {
            menuOpen     : false,
            menuOpenClass: '',
            routes       : [
                {
                    name   : 'dashboard',
                    caption: '',
                    class  : 'dashboard',
                    title  : 'Dashboard'
                },
                {
                    name   : 'scenes',
                    caption: '',
                    class  : 'scenes',
                    title  : 'Szenen & Scripts'
                },
                {
                    name   : 'cameras',
                    caption: '',
                    class  : 'cameras',
                    title  : 'Kameras'
                },
                {
                    name   : 'settings',
                    caption: '',
                    class  : 'settings',
                    title  : 'Einstellungen'
                }
            ]
        }
    },

    methods: {

        handleChange()
        {

            let elm = document.querySelector( '#menu-open' )
            if( elm.checked === true )
            {
                document.querySelector( '#menu' ).classList.add( 'open' )
                this.menuOpen = true
                this.menuOpenClass = ' checked'
                return
            }
            document.querySelector( '#menu' ).classList.remove( 'open' )
            this.menuOpen = false
            this.menuOpenClass = ''

        },

        getFillers()
        {
            let fillers = []

            for( let i = 0; i < ( Math.floor( ( 8 - this.routes.length ) / 2 ) ); i++ )
            {
                fillers.push( { key: i } )
            }
            return fillers
        },

        forceChange()
        {
            let elm = document.querySelector( '#menu-open' )
            elm.checked = !elm.checked
            setTimeout( () => {
                this.menuOpen = elm.checked
                this.handleChange()
            }, 500 )
        },

        navigate( where )
        {
            if( this.menuOpen )
            {
                this.$router.push( '/'+where )
            }
        }
    }

}
</script>