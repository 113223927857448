<template>
    <div class="online-status" :key="viewKey">
        <div class="status">
            <template v-if="$store.getters.localConnected">
                <div class="status ok">
                    <span v-if="$store.getters.localAuthorized">&#11044;</span>
                    <span v-else>&#9711;</span>
                </div>
            </template>
            <template v-else>
                <div class="status bad">
                    <span>&#11044;</span>
                </div>
            </template>
            <div class="label"><strong>WIFI</strong></div>
        </div>
        <div class="clearfix"></div>
        <div class="status">
            <template v-if="$store.getters.remoteConnected">
                <div class="status ok">
                    <span v-if="$store.getters.remoteAuthorized">&#11044;</span>
                    <span v-else>&#9711;</span>
                </div>
            </template>
            <template v-else>
                <div class="status bad">
                    <span>&#11044;</span>
                </div>
            </template>
            <div class="label"><strong>LINK</strong></div>
        </div>
        <div class="clearfix"></div>
    </div>
</template>

<script>
export default {
    name: "OnlineStatus",
    data()
    {
        return {
            viewKey: false
        }
    },
    created()
    {
        this.$core.getEventManager()
            .add( 'on-connection-state-change', () =>
            {
                this.viewKey = this.$core.getUuid().generate()
            } )
        this.$core.getCoreTimer()
            .addInterval( 'connection-state-display', 10000, () => {
                this.viewKey = this.$core.getUuid().generate()
            })
    },
    beforeUnmount()
    {
        this.$core.getEventManager()
            .remove( 'on-connection-state-change' )
        this.$core.getCoreTimer()
            .removeInterval( 'connection-state-display' )
    }
}
</script>