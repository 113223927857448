import WebSocketClient from "@/classes/WebSocketClient";

export default class WebSockets
{

    constructor( core )
    {

        if( !WebSockets.instance )
        {

            this.core = core
            this.store = core.getStore()
            this.config = core.getConfig()
            this.keyRing = core.getKeyRing()
            this.logger = core.getLogger()
            this.eventManager = core.getEventManager()

            this.sockets = {
                local : false,
                remote: false
            }

            this.ready = false
            this.setup()
            this.logger.clog( 'WebSockets', 'initializing...' )

            this.eventManager.add( 'after-provisioning-startover', () => {
                this.startOver()
            } )

            WebSockets.instance = this

        }

        return WebSockets.instance

    }

    awaitReadiness()
    {
        return new Promise( resolve =>
        {

            if( this.ready )
            {
                return resolve()
            }
            else
            {
                setTimeout( () =>
                {
                    return resolve( this.awaitReadiness() )
                }, 300 )
            }

        } )
    }

    setup()
    {

        this.keyRing
            .awaitReadiness()
            .then( () =>
            {

                let localUri  = 'wss://' + this.keyRing.ring.openSetup.userid + '.connect.customsmarthome.de:6699/',
                    remoteUri = this.config.websocketUrl

                this.sockets.local = new WebSocketClient( this.core, 'local', localUri, this.keyRing.ring.openSetup.userid )
                this.sockets.remote = new WebSocketClient( this.core, 'remote', remoteUri, this.keyRing.ring.openSetup.userid )

                setTimeout( () =>
                {
                    this.ready = true
                }, 1000 )

            } )

    }

    startOver()
    {
        delete this.sockets.local
        delete this.sockets.remote
        this.setup()
    }

    command( command, scope )
    {
        return new Promise( ( resolve, reject ) =>
        {

            if( undefined !== scope )
            {
                this.sockets[ scope ].command( command )
                                     .then( response =>
                                     {
                                         return resolve( response.data.result )
                                     } )
                                     .catch( e =>
                                     {
                                         return reject( e )
                                     } )
            }
            else
            {

                if( true === this.store.getters.localConnected
                    && true === this.store.getters.localAuthorized )
                {
                    this.command( command, 'local' )
                        .then( response =>
                        {
                            return resolve( response )
                        } )
                        .catch( e =>
                        {
                            return reject( e )
                        } )
                }
                else
                {
                    this.command( command, 'remote' )
                        .then( response =>
                        {
                            return resolve( response )
                        } )
                        .catch( e =>
                        {
                            return reject( e )
                        } )
                }

            }
        } )
    }

    request( request, scope )
    {

        return new Promise( ( resolve, reject ) =>
        {

            if( !this.ready )
            {
                setTimeout( () =>
                {
                    return resolve( this.request( request, scope ) )
                }, 500 )
            }
            else
            {


                if( undefined !== scope )
                {
                    this.sockets[ scope ].request( request )
                                         .then( response =>
                                         {
                                             return resolve( response.data.result )
                                         } )
                                         .catch( e =>
                                         {
                                             return reject( e )
                                         } )
                }
                else
                {

                    if( true === this.store.getters.localConnected
                        && true === this.store.getters.localAuthorized )
                    {
                        this.request( request, 'local' )
                            .then( response =>
                            {
                                return resolve( response )
                            } )
                            .catch( e =>
                            {
                                return reject( e )
                            } )
                    }
                    else
                    {
                        this.request( request, 'remote' )
                            .then( response =>
                            {
                                return resolve( response )
                            } )
                            .catch( e =>
                            {
                                return reject( e )
                            } )
                    }

                }

            }
        } )

    }

}