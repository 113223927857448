<template>
    <transition appear name="slide-fade">
        <RoomSelectorOverlay v-if="roomSelectorVisible"
                             :topic="topic"
                             @close="roomSelectorVisible = false"
                             @select="handleSelect"/>
    </transition>
    <div class="roomselector-boundaries">
        <div v-if="roomsEnabled && $core.getDevices().cache.rooms.keys.length > 0"
             class="roomselector light-shadowed design-border-bottom design-border-top"
             @click="handleClick">
            {{ topic }} wählen
        </div>
        <div v-if="sortingEnabled"
             :class="'roomselector drag light-shadowed design-border-bottom design-border-top sort-'+( sortingActive ? 'active' : 'inactive' )"
             @click="handleSort">
        </div>
        <div class="clearfix"></div>
    </div>
</template>

<script>
import RoomSelectorOverlay from "@/components/layout/header/RoomSelector/Overlay";

export default {

    name      : "RoomSelector",
    components: { RoomSelectorOverlay },
    emits     : [ 'select' ],

    props: {
        roomsEnabled  : { Type: Boolean, default: false },
        sortingEnabled: { Type: Boolean, default: false }
    },

    data()
    {
        return {
            roomSelectorVisible: false,
            sortingActive      : false
        }
    },

    computed:
        {
            topic()
            {
                switch( this.$route.name )
                {
                    case 'dashboard':
                        return 'Raum'
                    case 'scenes':
                        return 'Thema'
                    case 'cameras':
                        return 'Raum'
                }
                return false
            }
        },

    methods: {
        handleClick( event )
        {
            this.$core.f().skip( event )
            this.roomSelectorVisible = !this.roomSelectorVisible
        },
        handleSelect( room )
        {
            this.$emit( 'select', room )
        },
        handleSort( event )
        {
            this.$core.f().skip( event )
            this.sortingActive = !this.sortingActive
            this.$core.setRState( 'sortingActive', this.sortingActive )
        }
    }

}
</script>