export default class KeyRing
{

    constructor( core )
    {

        if( !KeyRing.instance )
        {

            this.config = core.getConfig()
            this.crypto = core.getCrypto()
            this.logger = core.getLogger()
            this.eventManager = core.getEventManager()
            this.database = core.getDatabase()
            this.store = core.getStore()

            this.logger.log( 'KeyRing warming up' )
            this.ready = false
            this.ring = {}

            this.setup()

            KeyRing.instance = this

        }

        return KeyRing.instance

    }

    awaitReadiness()
    {
        return new Promise( resolve => {

            if( this.ready )
            {
                return resolve()
            }
            else
            {
                setTimeout( () => {
                    return resolve( this.awaitReadiness() )
                }, 300 )
            }

        })
    }

    setup( reset )
    {

        this.database.readStorage( 'provisioning' )
            .then( keyring =>
            {

                this.ring = JSON.parse( keyring )
                this.ring.keys.privateUint = this.crypto._decryptPrivateKey( this.ring.keys.private, "000000" )
                this.ring.openSetup = this.crypto.decryptWithPrivateKey( this.ring.keys.privateUint, this.ring.setup )

                this.crypto.privateKey = this.ring.keys.private
                this.crypto.publicKey = this.ring.keys.public

                if( false !== this.ring.openSetup )
                {
                    this.ring.openSetup = JSON.parse( this.ring.openSetup )
                }

                if( reset )
                {
                    this.eventManager.dispatch( 'after-provisioning-startover' )
                }

                this.logger.clog( 'KeyRing::setup', 'ring opened and ready' )
                this.ready = true

            })

    }

}