<template>
    <div class="router-view">
        <div class="content" id="scrollable">
            <router-view v-slot="{ Component }">
                <transition appear name="fade" mode="out-in">
                    <component :is="Component"/>
                </transition>
            </router-view>
        </div>
    </div>
</template>

<script>
export default {
    name: "Content"
}
</script>