import CoreTimerWorker from '@/classes/CoreTimerWorker'

export default class CoreTimer
{
    
    constructor( core )
    {
        
        if( !CoreTimer.instance )
        {
            
            this.logger = core.getLogger()
            this.eventManager = core.getEventManager()
            
            this.events = {}
            this.triggers = []
            this.t = 0

            this.resetIndex = this.eventManager.addIndexed( 'core-component-reset', () =>
            {
                
                this.setup()
                
            } )
            
            CoreTimer.instance = this
            
            this.coreTimerWorker = new CoreTimerWorker( this.logger )
            this.setup()

        }
        
        return CoreTimer.instance
        
    }
    
    setup()
    {
        
        this.logger.clog( 'CoreTimer::setup', 'setting up main timing instance...' )
        
        if( this.timer )
        {
            this.coreTimerWorker.doClearInterval( this.timer )
        }
        
        this.timer = this.coreTimerWorker.doSetInterval( () =>
        {

            if( this.t%30 === 0 )
            {
                let eventCount = Object.keys( this.events ).length,
                    triggerCount = this.triggers.length

                this.logger.cdebug( 'CoreTimer::Statistics', eventCount, 'events |',triggerCount, 'triggers' )
            }

            this.t++
            this.handleTimer()
            
        }, 300 )
        
    }
    
    destruct()
    {
        this.eventManager.unregisterIndexedCallback( 'core-component-reset', this.resetIndex )
        this.coreTimerWorker.doClearInterval( this.timer )
        delete CoreTimer.instance
    }
    
    handleTimer()
    {

        if( 0 === this.triggers.length )
        {
            return
        }

        let now = Date.now(),
            dones = []
        
        for( let t in this.triggers )
        {
            
            let trigger = this.triggers[ t ],
                triggerEvent = this.events[ trigger ],
                fired = false
            
            if( undefined !== triggerEvent )
            {

                if( now >= ( triggerEvent.lastTrigger + ( triggerEvent.interval ) ) )
                {

                    fired = true
                    setTimeout( () => {

                        triggerEvent.callback()
                        triggerEvent.lastTrigger = now

                    }, 0)

                }
                
                if( triggerEvent.singleShot !== true
                    || ( triggerEvent.singleShot === true && !fired ) )
                {
                    this.events[ trigger ] = triggerEvent
                    this.triggers[ t ] = trigger
                }
                
                if( triggerEvent.singleShot === true && fired )
                {
                    dones.push( t )
                    delete this.events[ trigger ]
                }
            }
            
        }
        
        let newTriggers = []
        
        for( let t in this.triggers )
        {
            if( -1 === dones.indexOf( t ) )
            {
                newTriggers.push( this.triggers[ t ] )
            }
        }
    
        this.triggers = newTriggers

    }
    
    registerEvent( name, interval, callback, singleShot, fireOnCreate )
    {

        this.removeInterval( name )

        this.events[ name ] = {
            interval   : interval,
            lastTrigger: ( fireOnCreate ? 0 : Date.now() ),
            callback   : callback,
            singleShot : singleShot
        }

        this.triggers.push( name )
        
    }
    
    unregisterEvent( name )
    {
        delete this.events[ name ]
    }
    
    addTimeout( name, interval, callback )
    {
        this.registerEvent( name, interval, callback, true, undefined )
    }
    
    addInterval( name, interval, callback, fireOnCreate )
    {
        fireOnCreate = undefined === fireOnCreate ? false : fireOnCreate
        this.registerEvent( name, interval, callback, false, fireOnCreate )
    }
    
    changeInterval( name, interval )
    {
        this.events[ name ].interval = interval
    }
    
    removeInterval( name )
    {
        
        let index = this.triggers.indexOf( name )
        while( -1 < index )
        {
            this.triggers.splice( index, 1 )
            if( undefined !== this.events[ name ] )
            {
                delete ( this.events[ name ] )
            }
            index = this.triggers.indexOf( name )
        }

    }

    hasTimer( name )
    {
        return -1 < this.triggers.indexOf( name )
    }
    
    removeTimeout( name )
    {
        this.removeInterval( name )
    }
    
}