let dumpStart = '\n>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>\n'
let dumpEnd = '\n<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<\n'
window.consoleErrors = []

function getTimestamp()
{

    let date = new Date()

    return ( 10 > date.getDate() ? '0' : '' ) + date.getDate()
           + '.' + ( 10 > ( 1 + date.getMonth() ) ? '0' : '' ) + ( 1 + date.getMonth() )
           + '.' + date.getFullYear()
           + ' ' + ( 10 > date.getHours() ? '0' : '' ) + date.getHours()
           + ':' + ( 10 > date.getMinutes() ? '0' : '' ) + date.getMinutes()
           + ':' + ( 10 > date.getSeconds() ? '0' : '' ) + date.getSeconds()
           + ' (' + date.getTime() + ')'

}

function getCaller( caller )
{

    if( undefined !== caller )
    {
        return caller
    }

    let stack = ( new Error() ).stack
    let lines = []
    if( undefined !== stack )
    {
        let error = stack.toString()
        lines = error.split( /\n/ )
    }

    let loggerLineFound = false
    let logline = false

    while( !logline )
    {

        let line = lines.shift()
        if( undefined !== line )
        {
            if( loggerLineFound
                && -1 == line.indexOf( 'Logger.' ) )
            {
                logline = line
            }
            if( -1 < line.indexOf( 'Logger.' ) )
            {
                loggerLineFound = true
            }
        }
        else
        {
            return ''
        }

    }

    logline = logline.trim().replace( 'at ', '' )
    if( -1 < logline.indexOf( 'eval' ) )
    {
        let parts = logline.split( /\// )
        logline = 'eval :: ' + parts[ ( parts.length - 1 ) ].replace( ')', '' )
    }
    else
    {
        let parts = logline.split( ' (' )
        logline = parts.shift()
    }

    return logline

}

function getArguments( args )
{

    let returnValues = []

    for( let i = 0; i < args.length; i++ )
    {
        returnValues.push( args[ i ] )
    }

    return returnValues.join( ' ' )

}

export default class Logger
{

    constructor( config )
    {

        if( !Logger.instance )
        {

            this.config = config
            this.originalConsoleError = false
            this.coreTimer = false

            this.defaultErrorHandler = ( event ) =>
            {
                this.handleErrorEvent( event )
            }

            this.defaultCatchHandler = ( event ) =>
            {
                this.handleUncaughtEvent( event )
            }

            this.setupErrorHandler()

            Logger.instance = this

        }

        return Logger.instance

    }

    setupErrorHandler()
    {

        window.onerror = ( message, source, lineno, colno, error ) =>
        {
            this.handleOnError( message, source, lineno, colno, error )
        };

        window.addEventListener( "error", this.defaultErrorHandler, true )
        window.addEventListener( 'unhandledrejection', this.defaultCatchHandler, true )

    }

    disableErrorHandler()
    {
        window.onerror = null
        window.removeEventListener( 'error', this.defaultErrorHandler, true )
        window.removeEventListener( 'unhandledrejection', this.defaultCatchHandler, true )
    }

    destruct()
    {
        delete Logger.instance
    }

    logOut( severity, message, object, dumpEnd )
    {

        if( 5000 < this.linecount )
        {
            console.clear()
            this.linecount = 0
        }

        this.linecount++

        switch( severity )
        {
            case 'error':
                console.info( message )
                break
            case 'constructed':
                console.info( message )
                break
            case 'dump':
            case 'trace':
                console.debug( message, object, dumpEnd )
                break
            default:
                console.info( message )
                break
        }

    }

    getArgs( args )
    {

        let returnValues = []
        for( let line in args )
        {
            if( 'object' !== typeof args[line] )
            {
                returnValues.push( args[line] )
            }
            else
            {
                for( let l in args[line] )
                {
                    if( 'object' !== typeof args[line][l] )
                    {
                        returnValues.push( args[line][l] )
                    }
                }
            }
        }

        return returnValues

    }

    _resolve()
    {

        let args = this.getArgs( arguments ),
            caller = args.shift(),
            message = args.join( ' ' ) //this.getArgs( args )

        return [ args, caller, message ]

    }

    cerror()
    {
        let [ args, caller, message ] = this._resolve( arguments )
        window.consoleErrors.push( getTimestamp() + ' [ERROR] :: ' + caller + ' :: ' + message )
        this.logOut( 'error', getTimestamp() + ' [ERROR] :: ' + caller + ' :: ' + message )
    }

    cconsoleerror()
    {

        let [ args, caller, message ] = this._resolve( arguments )
        window.consoleErrors.push( getTimestamp() + ' [CERROR] :: ' + caller + ' :: ' + message )
        this.logOut( 'error', getTimestamp() + ' [CERROR] :: ' + caller + ' :: ' + message )

    }

    error()
    {
        let message = getArguments( arguments )
        window.consoleErrors.push( getTimestamp() + ' [ERROR] :: ' + getCaller() + ' :: ' + message )
        this.logOut( 'error', getTimestamp() + ' [ERROR] :: ' + getCaller() + ' :: ' + message )
    }

    clog()
    {
        let [ args, caller, message ] = this._resolve( arguments )
        this.logOut( 'log', getTimestamp() + ' [LOG] :: ' + caller + ' :: ' + message )
    }

    log()
    {
        let message = getArguments( arguments )
        this.logOut( 'log', getTimestamp() + ' [LOG] :: ' + getCaller() + ' :: ' + message )
    }

    success()
    {
        let message = getArguments( arguments )
        this.logOut( 'log', getTimestamp() + ' [SUCCESS] :: ' + getCaller() + ' :: ' + message )
    }

    cconstructed()
    {
        let [ args, caller, message ] = this._resolve( arguments )
        this.logOut( 'constructed', getTimestamp() + ' [CLASS_CONSTRUCTED] :: ' + caller + ' :: ' + message )
    }

    constructed()
    {
        let message = getArguments( arguments )
        this.logOut( 'constructed', getTimestamp() + ' [CLASS_CONSTRUCTED] :: ' + getCaller() + ' :: ' + message )
    }

    cdump( caller, param, object )
    {
        if( this.config.verbosity > 1 )
        {
            this.logOut( 'dump', getTimestamp() + ' [PARAMETER_DUMP] :: ' + caller + ' :: ' + param + dumpStart,
                object,
                dumpEnd )
        }
    }

    ctrace( caller, message )
    {
        if( this.config.verbosity > 1 )
        {

            let trace = ''
            try
            {
                throw new Error( 'trace' )
            }
            catch( e )
            {
                trace = e
            }

            this.logOut( 'trace', getTimestamp() + ' [TRACE] :: ' + caller + ' :: ' + message + dumpStart,
                trace,
                dumpEnd )
        }
    }

    csuccess()
    {
        let [ args, caller, message ] = this._resolve( arguments )
        this.logOut( 'log', getTimestamp() + ' [SUCCESS] :: ' + caller + ' :: ' + message )
    }

    debug()
    {
        if( this.config.verbosity > 1 )
        {
            let message = getArguments( arguments )
            this.logOut( 'debug', getTimestamp() + ' [DEBUG] :: ' + getCaller() + ' :: ' + message )
        }
    }

    cdebug()
    {
        if( this.config.verbosity > 1
            || window.debugMode )
        {
            let [ args, caller, message ] = this._resolve( arguments )
            this.logOut( 'debug', getTimestamp() + ' [DEBUG] :: ' + caller + ' :: ' + message )
        }
    }

    dump( param, object )
    {
        if( this.config.verbosity > 1 )
        {
            this.logOut( 'dump', getTimestamp() + ' [PARAMETER_DUMP] :: ' + getCaller() + ' :: ' + param + dumpStart,
                object,
                dumpEnd )
        }
    }

    handleOnError( message, source, lineno, colno, error )
    {
        this.cconsoleerror( 'Logger::onError', message, source, 'Line: ' + lineno, 'Col: ' + colno, error )
    }

    plainErrorMessage( err )
    {

        if( undefined === err )
        {
            return ''
        }

        let stack = err.split( /\n/g ),
            line = '' + stack.shift(),
            out = line.trim()

        while( 0 < stack.length )
        {
            line = '' + stack.shift()
            out += '[[BR]]   ' + line.trim()
        }

        return out

    }

    handleErrorEvent( e )
    {
        if( null !== e.error
            && null !== e.error.message )
        {
            this.cconsoleerror( 'Logger::onErrorEvent', this.plainErrorMessage( e.error.message ) )
        }
    }

    handleUncaughtEvent( e )
    {
        if( e.reason !== undefined
            && e.reason.stack !== undefined )
        {
            this.cconsoleerror( 'Logger::onUncaughtError', this.plainErrorMessage( e.reason.stack ) )
        }
        else
        {
            this.cconsoleerror( 'Logger::onUncaughtError', JSON.stringify( e ) )
        }
    }

    rewriteConsole( value )
    {
        if( 'function' === typeof console.error )
        {
            if( value === true )
            {
                this.originalConsoleError = console.error
                let that = this
                console.error = function()
                {
                    that.cconsoleerror( 'Logger::nativeConsoleError', ...arguments )
                }
            }
            else
            {
                if( false !== this.originalConsoleError )
                {
                    console.error = this.originalConsoleError
                    this.originalConsoleError = false
                }
            }
        }
    }

}
