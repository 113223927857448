<template>
    <div class="roomselector-overlay">
        <div class="selector design-border-top design-border-bottom shadowed">
            <div class="button round closer" @click="$emit('close')">X</div>
            <h1 class="design-border-bottom">{{ topic }} wählen</h1>
            <div class="roomselector-inner">
                <RoomIcon v-for="room in rooms"
                          :key="'room-icon-'+room.id"
                          :room="room"
                          @click="handleClick( $event, room.id )"/>
            </div>
        </div>
    </div>
</template>

<script>
import RoomIcon from "@/components/layout/header/RoomSelector/RoomIcon";

export default {

    name      : "RoomSelectorOverlay",
    components: { RoomIcon },
    emits     : [ 'select', 'close' ],

    props: {
        topic: { Type: String, required: true }
    },

    data()
    {
        return {
            rooms: []
        }
    },

    created()
    {

        let tempList = [],
            scope    = this.$route.name,
            key

        switch( scope )
        {
            case 'dashboard':
                key = 'rooms'
                break
            case 'scenes':
                key = 'categories'
                break
            case 'cameras':
                key = null
                break
            case 'settings':
                key = null
                break
        }


        let roomKeys = this.$core.getDevices().cache[ key ].keys

        for( let k in roomKeys )
        {

            let room = this.$core.getDevices().cache[ key ].map.get( roomKeys[ k ] )
            tempList.push( room )

        }

        this.rooms = this.$core.getSorter().sortObjects( tempList, 'sortOrder', 'ascending' )

    },

    methods: {
        handleClick( event, room )
        {
            event.stopPropagation()
            this.$emit( 'select', room )
            setTimeout( () =>
            {
                this.$emit( 'close' )
            }, 100 )
        }
    }
}
</script>