export default class Functions
{

    constructor()
    {
        return this
    }

    ucFirst( string )
    {
        return string.charAt( 0 ).toUpperCase() + string.slice( 1 )
    }

    lcFirst( string )
    {
        return string.charAt( 0 ).toLowerCase() + string.slice( 1 )
    }

    deviceHash( item, returnValue )
    {

        if( undefined !== item.elementKey )
        {
            delete item.elementKey
        }

        let str = JSON.stringify( item )
        let i, l, key,
            hval = 0x2961987

        for( i = 0, l = str.length; i < l; i++ )
        {
            hval ^= str.charCodeAt( i )
            hval += ( hval << 1 ) + ( hval << 4 ) + ( hval << 7 ) + ( hval << 8 ) + ( hval << 24 )
        }

        key = ( '00000000' + ( hval >>> 0 ).toString( 16 ) ).substr( -8 )

        if( 'object' === typeof item
            && undefined === returnValue )
        {
            item.elementKey = key
        }

        return key

    }

    /**
     * skip
     * @param event
     */
    skip( event )
    {
        if( undefined !== event )
        {
            try
            {
                event.preventDefault()
                event.stopPropagation()
            }
            catch
            { /*is-empty-block*/
            }
        }
    }

    promiseRunner( promises )
    {
        return new Promise( resolve =>
        {

            let promise = promises.reduce( ( todo, task ) =>
            {
                return todo.then( () =>
                {
                    return task()
                } )
            }, Promise.resolve() )

            promise.then( () =>
            {
                return resolve()
            } )

        } )
    }

    /**
     * removeFromArray
     * @param array
     * @param what
     */
    removeFromArray( array, what )
    {

        if( undefined === array )
        {
            return
        }

        let index = array.indexOf( what )
        if( -1 < index )
        {
            array.splice( index, 1 )
        }

    }

    /**
     * formattedDate
     * @param timestamp
     * @returns {string}
     */
    formattedDate( timestamp )
    {

        if( undefined === timestamp )
        {
            timestamp = Date.now()
        }

        let date  = new Date( timestamp ),
            day   = date.getDate(),
            month = date.getMonth() + 1,
            year  = date.getFullYear()

        if( isNaN( year ) )
        {
            return null
        }

        return ( 10 > day ? '0' : '' ) + day + '.' + ( 10 > month ? '0' : '' ) + month + '.' + year

    }

    float( value )
    {
        let number = parseFloat( ( '' + value ).replace( ',', '.' ) ).toFixed( 2 ),
            test = ( ''+number ).split( '.' )
        if( undefined !== test[1] )
        {
            if( '00' === test[1] )
            {
                return parseFloat( test[0] )
            }
            if( '0' === test[1].substr( 1, 1 ) )
            {
                return parseFloat( parseFloat( number ).toFixed( 1 ) )
            }
        }
        return parseFloat( number )
    }

    localizedFloat( value, precision )
    {

        value = this.float( value )
        if( isNaN( value ) )
        {
            return undefined
        }

        value = value.toFixed( precision )
        return ( '' + value ).replace( '.', ',' )

    }

}