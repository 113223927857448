<template>
    <footer class="design-border-top shadowed">
        <Navigation/>
        <div class="footer-content">
            &copy;2016-{{ year }} <strong>customsmarthome.de</strong> | Version {{ $core.getConfig().version }}
        </div>
    </footer>
</template>

<script>
import Navigation from "@/components/layout/footer/Navigation";

export default {

    name      : "Footer",
    components: { Navigation },
    computed  :
        {
            year()
            {
                return new Date().getFullYear()
            }
        }

}
</script>