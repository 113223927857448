<template>
    <div class="controls shutter">
        <h1>Tor steuern</h1>
        <div class="button round closer" @click="$emit('close')">X</div>
        <div class="buttonspace shutters">
            <div :class="'control open '+( visualState === 'closed' ? 'off' : 'on' )" @click="handle( 'open' )">
            </div>
            <div :class="'control close '+( visualState === 'opened' ? 'off' : 'on' )" @click="handle( 'close' )">
            </div>
        </div>
        <div class="buttonspace shutters">
            <div :class="'control stepUp'" @click="handle( 'stepUp' )">
                <div class="clabel">1s hoch</div>
            </div>
            <div :class="'control stepDown'" @click="handle( 'stepDown' )">
                <div class="clabel">1s runter</div>
            </div>
        </div>
        <div class="buttonspace emergency">
            <div :class="'control halt'" @click="handle( 'halt' )">
                <div class="clabel">Not-Aus</div>
            </div>
        </div>
        <div class="label">
            Status: <strong>{{ 'opened' === visualState ? 'geöffnet' : 'geschlossen' }}</strong>
        </div>
    </div>
</template>

<script>
export default {
    name : "GateControl",
    props: {
        device: { type: Object, required: true }
    },
    emits: [ 'close' ],
    data()
    {
        return {
            state       : false,
            visualState : false,
            isNumeric   : false,
            numericState: 0
        }
    },
    created()
    {

        let state = this.$props.device.internals.state
        this.visualState = state
        console.log( this.$props.device.name, this.$props.device.internals.state, this.$props.device.stateInversion, this.visualState )

    },
    methods: {
        handle( direction )
        {

            let command     = 'set ' + this.$props.device.name + ' ' + direction,
                visualState = 'up' === direction ? 'opened' : 'closed'

            this.$core
                .getClient()
                .command( command )

            this.visualState = visualState

        }
    }
}
</script>