/* eslint-disable */
import { createStore } from 'vuex'

export const store = createStore( {

    state()
    {
        return {
            dbConnected     : false,
            online          : false,
            localConnected  : false,
            remoteConnected : false,
            localAuthorized : false,
            remoteAuthorized: false,
            storeInitialized: false,
            localSessionId  : false,
            remoteSessionId : false
        }
    },

    mutations: {

        setDatabase( state, value )
        {
            this.database = value
            state.dbConnected = true
        },

        setOnline( state, value )
        {
            state.online = value
        },

        setLocalConnected( state, value )
        {
            state.localConnected = value
        },

        setRemoteConnected( state, value )
        {
            state.remoteConnected = value
        },

        setLocalAuthorized( state, value )
        {
            state.localAuthorized = value
            this.database.writeStorage( 'localAuthorized', value )
        },

        setRemoteAuthorized( state, value )
        {
            state.remoteAuthorized = value
            this.database.writeStorage( 'remoteAuthorized', value )
        },

        setLocalSessionId( state, value )
        {
            state.localSessionId = value
            this.database.writeStorage( 'localSessionId', value )
        },

        setRemoteSessionId( state, value )
        {
            state.remoteSessionId = value
            this.database.writeStorage( 'remoteSessionId', value )
        },

        setStoreInitialized( state, value )
        {
            state.storeInitialized = value
        }

    },

    getters: {

        online          : state => state.online,
        localConnected  : state => state.localConnected,
        remoteConnected : state => state.remoteConnected,
        localAuthorized : state => state.localAuthorized,
        remoteAuthorized: state => state.remoteAuthorized,
        localSessionId  : state => state.localSessionId,
        remoteSessionId : state => state.remoteSessionId,
        storeInitialized: state => state.storeInitialized

    },

    actions: {

        initStates( { commit } )
        {

            return new Promise( resolve => {

            let promises = []

            for( const [ key, value ] of Object.entries( this.state ) )
            {

                if( 'dbConnected' !== key
                    && 'online' !== key )
                {

                    let todo = 'set' + key.charAt( 0 ).toUpperCase() + key.slice( 1 )

                    promises.push( new Promise( resolve =>
                    {

                        this.database.readStorage( key )
                            .then( result =>
                            {
                                commit( todo, result )
                                return resolve()

                            } )
                            .catch( () =>
                            {
                                commit( todo, value )
                                return resolve()
                            } )

                    } ) )

                }

            }

            Promise.all( promises )
                   .then( () =>
                   {

                       commit( 'setStoreInitialized', true )
                       return resolve()

                   } )

            })

        },

        destroySession( { commit } )
        {
            return new Promise( ( resolve ) =>
            {

                commit( 'setLocalAuthorized', false )
                commit( 'setRemoteAuthorized', false )
                commit( 'setLocalSessionId', false )
                commit( 'setRemoteSessionId', false )
                return resolve()

            } )
        },

        flushStorage( { commit } )
        {

            return new Promise( ( resolve ) =>
            {

                this.dispatch( 'destroySession' )
                    .then( () =>
                    {

                        commit( 'setLocalConnected', false )
                        commit( 'setRemoteConnected', false )
                        commit( 'setStoreInitialized', false )
                        commit( 'setOnline', false )
                        return resolve()

                    } )

            } )

        }

    }

} )