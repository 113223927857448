<template>
    <div class="controls shutter">
        <h1>Hue steuern</h1>
        <div class="button round closer" @click="$emit('close')">X</div>
        <div class="buttonspace wides">
            <div :class="'control light '+visualState" @click="handle( ( visualState === 'on' ? 'off' : 'on' ) )">
            </div>
        </div>
        <div class="buttonspace shutters">
            <div class="control colorpicker">
                <input class="colorpreview" type="color" :value="'#'+rgb" @change="handleRgb"/>
            </div>
            <div class="control dimUpDown">
                <div class="dimUp" @click="dim( 'up' )">
                    +
                </div>
                <div class="dimDown" @click="dim( 'down' )">
                    -
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : "HueControl",
    props: {
        device: { type: Object, required: true }
    },
    emits: [ 'close' ],
    data()
    {
        return {
            state       : false,
            visualState : false,
            rgb         : false,
            isNumeric   : false,
            numericState: 0
        }
    },
    created()
    {

        let state = this.$props.device.internals.state
        this.visualState = state
        this.rgb = this.$props.device.readings.rgb.Value
        console.log( 'DBG >>>', this.rgb, this.$props.device, this.$props.device.internals.state, this.$props.device.stateInversion, this.visualState )

    },
    methods: {
        handle( newstate )
        {

            let command     = 'set ' + this.$props.device.name + ' ' + newstate,
                visualState = newstate

            this.$core
                .getClient()
                .command( command )

            this.visualState = visualState

        },

        handleRgb( event )
        {

            this.rgb = event.target.value.replace( '#', '' )
            let command = 'set '+this.$props.device.name+' rgb '+this.rgb
            console.log( '>>> DBG >>>', command )
            this.$core
                .getClient()
                .command( command )

        },

        dim( direction )
        {
            let cmd = ''
            switch( direction )
            {
                case 'up':
                    cmd = 'dimUp'
                    break
                case 'down':
                    cmd = 'dimDown'
                    break
            }

            let command = 'set '+this.$props.device.name+' '+cmd
            this.visualState = 'on'
            console.log( '>>> DBG >>>', command )
            this.$core
                .getClient()
                .command( command )

        }
    }
}
</script>