import nacl from 'tweetnacl'
import util from 'tweetnacl-util'

export default class Crypto
{

    constructor()
    {

        if( !Crypto.instance )
        {

            console.log( 'CryptoCore initializing' )

            this.messageKeyLength = 16
            this.paddingString = '~P*A*D~'
            this.paddingLength = 32
            this.randomCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            this.randomBackupCharacters = 'ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghkmnpqrstuvwxyz123456789'
            this.serverPublicKey = 'IsBMhBYWq4BIVeDPdcrYqSr3qWCC0yQoVdLMlshytQQ='

            this.keyMaterial = false
            this.privateKey = false
            this.publicKey = false
            this.PIN = '000000'

            Crypto.instance = this

        }

        return Crypto.instance

    }

    awaitReadiness()
    {
        return new Promise( resolve =>
        {

            if( !this.privateKey
                || !this.publicKey )
            {
                setTimeout( () =>
                {
                    return resolve( this.awaitReadiness() )
                }, 100 )
            }
            else
            {
                return resolve()
            }

        } )
    }

    _cutUTF8Passphrase( utf8 )
    {
        let result = new Uint8Array( 32 )
        for( let i = 0; i < 32; i++ )
        {
            result[ i ] = utf8[ i ]
        }
        return result
    }

    /**
     * _encryptPrivateKey
     * @param string privateKey - the "readable" private key
     * @param string passphrase - the passphrase
     * @returns object          - a key object, containing privateKey and nonce
     */
    _encryptPrivateKey( privateKey, passphrase )
    {

        passphrase = passphrase.padEnd( this.paddingLength, this.paddingString )
        let phrase = this._cutUTF8Passphrase( util.decodeUTF8( passphrase ) )
        let nonce = nacl.randomBytes( nacl.box.nonceLength )

        let encryptedPrivateKey = nacl.secretbox( privateKey,
            nonce,
            phrase )

        return {
            privateKey: util.encodeBase64( encryptedPrivateKey ),
            nonce     : util.encodeBase64( nonce )
        }

    }

    /**
     * generateRendomString
     * @param int length        - the wanted length of the random string
     * @param boolean useBackupChars - use the backup character set (without O, o, 0, j, i, l)
     * @returns string          - a random string of the given length
     */
    generateRandomString( length, useBackupChars )
    {

        var randomString = ''

        for( var i = 0; i < length; i++ )
        {
            switch( useBackupChars )
            {
                case true:
                    randomString += this.randomBackupCharacters.charAt( Math.floor( Math.random() * this.randomBackupCharacters.length ) )
                    break
                default:
                    randomString += this.randomCharacters.charAt( Math.floor( Math.random() * this.randomCharacters.length ) )
            }
        }

        return randomString

    }

    padPassphrase( passphrase )
    {
        return passphrase.padEnd( this.paddingLength, this.paddingString )
    }

    /**
     * generateKeyPair
     * @param string passphrase     - the passphrase, the keypair shall be secured with
     * @returns object              - object, containing pulic(string) and private key (string)
     */
    generateKeyPair( passphrase )
    {

        passphrase = passphrase.padEnd( this.paddingLength, this.paddingString )

        let ephemeralKeyPair = nacl.box.keyPair()
        let backup = this.generateRandomString( 12, true )

        let myPrivateKey = this._encryptPrivateKey( ephemeralKeyPair.secretKey, passphrase )
        let myBackupKey = this._encryptPrivateKey( ephemeralKeyPair.secretKey, backup )

        let jsonPrivate = JSON.stringify( myPrivateKey )
        let jsonBackup = JSON.stringify( myBackupKey )

        let keyObject = {
            public   : util.encodeBase64( ephemeralKeyPair.publicKey ),
            private  : btoa( jsonPrivate ),
            backup   : btoa( jsonBackup ),
            backupKey: backup
        }

        return keyObject

    }

    /**
     * encryptWithPublicKey
     * @param string publicKey      - the public key
     * @param string text           - the plaintext input string
     * @returns string              - encrypted result, base64 encoded string with a json object
     */
    encryptWithPublicKey( publicKey, text )
    {

        const localKey = nacl.box.keyPair()
        const pubKeyUInt8Array = util.decodeBase64( publicKey )
        const msgParamsUInt8Array = util.decodeUTF8( text )
        const nonce = nacl.randomBytes( nacl.box.nonceLength )

        const encryptedMessage = nacl.box(
            msgParamsUInt8Array,
            nonce,
            pubKeyUInt8Array,
            localKey.secretKey
        )

        let encryptedResult = {
            ciphertext : util.encodeBase64( encryptedMessage ),
            ephemPubKey: util.encodeBase64( localKey.publicKey ),
            nonce      : util.encodeBase64( nonce ),
            version    : 'x25519-xsalsa20-poly1305'
        }

        return this.b64EncodeUnicode( JSON.stringify( encryptedResult ) )

    }

    b64EncodeUnicode( str )
    {
        // first we use encodeURIComponent to get percent-encoded UTF-8,
        // then we convert the percent encodings into raw bytes which
        // can be fed into btoa.
        return btoa( encodeURIComponent( str ).replace( /%([0-9A-F]{2})/g,
            function toSolidBytes( match, p1 )
            {
                return String.fromCharCode( '0x' + p1 )
            } ) )
    }

    b64DecodeUnicode( str )
    {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent( atob( str ).split( '' ).map( function( c )
        {
            return '%' + ( '00' + c.charCodeAt( 0 ).toString( 16 ) ).slice( -2 );
        } ).join( '' ) );
    }

    /**
     * _decryptPrivateKey
     * @param string privateKey - the encrypted private key
     * @param string passphrase - the passphrase to unlock the key
     * @returns string          - decrypted private key
     */
    _decryptPrivateKey( privateKey, passphrase )
    {

        passphrase = passphrase.padEnd( this.paddingLength, this.paddingString )
        let phrase = this._cutUTF8Passphrase( util.decodeUTF8( passphrase ) )
        let test = atob( privateKey )
        let privateJson = JSON.parse( test )

        let decryptedSecretKey = nacl.secretbox.open( util.decodeBase64( privateJson.privateKey ),
            util.decodeBase64( privateJson.nonce ),
            phrase )

        return decryptedSecretKey

    }

    /**
     * decryptWithPrivateKey
     * @param string decryptedPrivateKey    - the decrypted private key
     * @param string text                   - the encrypted jsonobject, base64 encoded
     * @returns string                      - the decrypted result
     */
    decryptWithPrivateKey( decryptedPrivateKey, text )
    {

        let cipheredObject = JSON.parse( this.b64DecodeUnicode( text ) )

        const nonce = util.decodeBase64( cipheredObject.nonce )
        const ciphertext = util.decodeBase64( cipheredObject.ciphertext )
        const ephemPubKey = util.decodeBase64( cipheredObject.ephemPubKey )

        const decryptedMessage = nacl.box.open(
            ciphertext,
            nonce,
            ephemPubKey,
            decryptedPrivateKey
        )

        return util.encodeUTF8( decryptedMessage )

    }

    decrypt( cryptText, privateKey, PIN )
    {

        privateKey = privateKey || this.privateKey
        PIN = PIN || this.PIN

        let decryptedPrivateKey = this._decryptPrivateKey( privateKey, PIN )
        if( false !== decryptedPrivateKey )
        {

            return this.decryptWithPrivateKey( decryptedPrivateKey, cryptText )

        }

    }

    encrypt( text )
    {

        return this.encryptWithPublicKey( this.publicKey, text )

    }

    decryptJson( cryptText, privateKey, PIN )
    {

        let json   = this.decrypt( cryptText, privateKey, PIN ),
            result = JSON.parse( json )

        if( 'Buffer' === result.type )
        {
            let arr = result.data
            return JSON.parse( util.encodeUTF8( arr ) )
        }

        return result

    }

    parseKeyStore( jsonObject )
    {

        let PIN = '000000',
            keyMaterial = jsonObject.keys,
            decryptedPrivateKey = this._decryptPrivateKey( keyMaterial.private, PIN )

        if( false !== decryptedPrivateKey )
        {

            let setup = this.decryptWithPrivateKey( decryptedPrivateKey, jsonObject.setup )
            if( false !== setup )
            {

                return {
                    setup: setup,
                    keys : jsonObject.keys
                }

            }

        }

        console.error( 'decryption failed.' )
        return false

    }

    encryptForServer( text )
    {
        return this.encryptWithPublicKey( this.serverPublicKey, text )
    }

}