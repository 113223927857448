<template>
    <header :class="'design-border-bottom shadowed'+( scrollState ? ' shrinked' : '' )" @click="scrollToTop()">
        <OnlineStatus/>
        <div :class="'logo'+( scrollState ? ' shrinked' : '' )" @click="$router.push('/')"></div>
        <RoomSelector :roomsEnabled="$route.meta.hasRoomSelector"
                      :sortingEnabled="$route.meta.hasSortingCapabilities"
                      @select="handleSelect"/>
    </header>
</template>

<script>
import RoomSelector from "@/components/layout/header/RoomSelector";
import OnlineStatus from "@/components/layout/header/OnlineStatus";

export default {
    name      : "Header",
    components: { OnlineStatus, RoomSelector },

    data()
    {
        return {
            scrollState: false
        }
    },

    created()
    {
        this.$core.getEventManager().add( 'on-scrolled-down', () =>
        {
            if( !this.scrollState )
            {
                this.scrollState = true
            }
        } )
        this.$core.getEventManager().add( 'on-scrolled-release', () =>
        {
            if( this.scrollState )
            {
                this.scrollState = false
            }
        } )
    },

    beforeUnmount()
    {
        this.$core.getEventManager().remove( 'on-scrolled-down' )
        this.$core.getEventManager().remove( 'on-scrolled-release' )
    },

    methods: {
        handleSelect( room )
        {
            let elm        = document.querySelector( '#room-' + room ),
                scrollable = document.querySelector( '.router-view' )

            if( null !== elm
                && null !== scrollable )
            {

                let position       = elm.getBoundingClientRect().top,
                    offsetPosition = scrollable.getBoundingClientRect().top,
                    scrollPosition = scrollable.scrollTop

                scrollable.scrollTo( {
                    behavior: "smooth",
                    left    : 0,
                    top     : ( ( scrollPosition - offsetPosition - 20 ) + position )
                } )

            }
        },
        scrollToTop()
        {
            let elm = document.querySelector( '.router-view' )
            elm.scroll( {
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }
    }
}
</script>