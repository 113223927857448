import { createApp } from 'vue'
import { store }     from "@/classes/Store";
import router        from "@/classes/Router";
import App           from './App.vue'
import CorePlugin    from "@/plugins/Core";


import './registerServiceWorker'

console.log( '%c * Custom Smarthome version ' + process.env.VERSION + '@' + process.env.BRANCH, 'background:#cefece; color:#2c992c;' )

const app = createApp( {
    extends: App
} ).use( router )
   .use( store )
   .use( CorePlugin, store, router )

app.mount( '#app' )