import { createWebHashHistory, createRouter } from 'vue-router'

const Startup = () => import( '@/components/routes/Startup' )
const Dashboard = () => import( '@/components/routes/Dashboard' )
const Scenes = () => import ( '@/components/routes/Scenes' )
const Cameras = () => import( '@/components/routes/Cameras' )
const Settings = () => import( '@/components/routes/Settings' )
const SoftwareUpdate = () => import( '@/components/routes/parts/settings/subs/SoftwareUpdate' )
const Backup = () => import( '@/components/routes/parts/settings/subs/Backup')
const Rooms = () => import( '@/components/routes/parts/settings/subs/Rooms' )
const Users = () => import( '@/components/routes/parts/settings/subs/Users' )

const routes = [
    {
        path     : '/',
        name     : 'default',
        meta     : { hasRoomSelector: false, hasSortingCapabilities: false },
        component: Startup
    },
    {
        path     : '/dashboard',
        name     : 'dashboard',
        meta     : { hasRoomSelector: true, hasSortingCapabilities: true },
        component: Dashboard
    },
    {
        path     : '/scenes',
        name     : 'scenes',
        meta     : { hasRoomSelector: true, hasSortingCapabilities: false },
        component: Scenes
    },
    {
        path     : '/cameras',
        name     : 'cameras',
        meta     : { hasRoomSelector: false, hasSortingCapabilities: false },
        component: Cameras
    },
    {
        path     : '/settings',
        name     : 'settings',
        meta     : { hasRoomSelector: false, hasSortingCapabilities: false },
        component: Settings
    },
    {
        path     : '/settings/softwareUpdate',
        name     : 'softwareUpdate',
        meta     : { hasRoomSelector: false, hasSortingCapabilities: false },
        component: SoftwareUpdate
    },
    {
        path     : '/settings/rooms',
        name     : 'rooms',
        meta     : { hasRoomSelector: false, hasSortingCapabilities: true },
        component: Rooms
    },
    {
        path     : '/settings/backup',
        name     : 'Backup',
        meta     : { hasRoomSelector: false, hasSortingCapabilities: false },
        component: Backup
    },
    {
        path     : '/settings/users',
        name     : 'Users',
        meta     : { hasRoomSelector: false, hasSortingCapabilities: false },
        component: Users
    }
]

const router = createRouter( {
    routes,
    history: createWebHashHistory(),
    scrollBehavior( to, from, savedPosition )
    {
        if( to.hash )
        {
            return {
                selector: to.hash,
                behavior: 'smooth'
            }
        }
    }
} )

router.beforeEach( ( to, from, next ) =>
{

    let element = document.querySelector( '.router-view' )
    if( null !== element )
    {
        element.scrollTo( { behavior: 'smooth', top: 0, left: 0 } )
    }

    next()

} )

export default router