export default class Ui
{
    constructor( core )
    {

        if( !Ui.instance )
        {

            this.logger = core.getLogger()
            this.eventManager = core.getEventManager()

            this.observer = null
            this.initialized = false
            this.routerView = null

            this.initializeScrollHandler()

            Ui.instance = this

        }

        return Ui.instance

    }

    initializeScrollHandler()
    {
        if( !this.initialized )
        {
            if( null !== document.querySelector( '.router-view' ) )
            {
                this.initialized = true
                this.routerView = document.querySelector( '.router-view' )
                this.routerView.addEventListener( 'scroll', ( event ) =>
                {
                    this.handleScroll( event )
                } )
            }
            else
            {
                setTimeout( () =>
                {
                    this.initializeScrollHandler()
                }, 300 )
            }
        }
    }

    handleScroll()
    {
        if( 30 < this.routerView.scrollTop )
        {
            this.eventManager.dispatch( 'on-scrolled-down' )
        }
        else
        {
            this.eventManager.dispatch( 'on-scrolled-release' )
        }
    }

    showBlocker( message )
    {
        this.eventManager.dispatch( 'on-show-blocker', message )
    }

    hideBlocker()
    {
        this.eventManager.dispatch( 'on-hide-blocker' )
    }


}