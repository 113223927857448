export default class Config
{

    constructor()
    {

        if( !Config.instance )
        {

            this.setConfig()
            Config.instance = this

        }

        return Config.instance

    }

    setConfig()
    {

        this.versionPrefix = '3.1.0'
        this.versionNumber = this.versionPrefix
        this.build = process.env.VERSION
        this.version = this.versionNumber + '-' + process.env.BRANCH + ' (' + this.build + ')'
        this.branch = process.env.BRANCH
        this.verbosity = 99

        this.server = 'core.customsmarthome.de'
        this.appSource = 'v3.customsmarthome.de'

        this.baseUrl = 'https://'+this.server+'/'
        this.appUrl = 'https://'+this.appSource+'/'

        this.websocketUrl = 'wss://link.customsmarthome.de/wss/'

        switch( this.branch )
        {
            case 'master':
            case 'dev':
                this.verbosity = 9
                break
            case 'beta':
                this.verbosity = 3
                break
            case 'live':
                this.verbosity = 1
                break
        }

        Config.instance = this

    }

    setLocalUrl( url )
    {
        this.websocketUrlLocal = url
    }

    refresh()
    {
        this.setConfig()
    }

    destruct()
    {
        delete Config.instance
    }

}
