<template>
    <Spinner v-if="blockerActive" :message="blockerMessage"/>
    <Header/>
    <FullScreenControls />
    <Content/>
    <Footer/>
</template>

<script>
import Header             from "@/components/layout/Header";
import Footer             from "@/components/layout/Footer";
import Content            from "@/components/layout/Content";
import Spinner            from "@/components/layout/Spinner";
import FullScreenControls from "@/components/routes/parts/devices/FullScreenControls";

export default {

    name      : 'App',
    components: { FullScreenControls, Spinner, Content, Footer, Header },

    data()
    {
        return {
            blockerActive : false,
            blockerMessage: ''
        }
    },

    created()
    {

        this.$core.getEventManager().add( 'on-show-blocker', ( message ) =>
        {

            this.blockerActive = true
            this.blockerMessage = message

        } )

        this.$core.getEventManager().add( 'on-hide-blocker', () =>
        {
            this.blockerActive = false
            this.blockerMessage = ''
        } )

    }

}
</script>
<style lang="less">
@import './less/ui.less';
</style>