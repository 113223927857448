<template>
    <div class="controls shutter">
        <h1>Jalousie steuern</h1>
        <div class="button round closer" @click="$emit('close')">X</div>
        <div class="buttonspace shutters">
            <div :class="'control up '+( visualState === 'off' ? 'off' : 'on' )" @click="handle( 'up' )">
                &#9650;
            </div>
            <div :class="'control down '+( visualState === 'on' ? 'off' : 'on' )" @click="handle( 'down' )">
                &#9660;
            </div>
        </div>
        <div class="label">
            <template v-if="isNumeric">
                Status: <strong>{{ numericState }}%</strong>
            </template>
            <template v-else>
                Status: <strong>{{ 'on' === visualState ? 'geöffnet' : 'geschlossen' }}</strong>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name : "ShutterControl",
    props: {
        device: { type: Object, required: true }
    },
    emits: [ 'close' ],
    data()
    {
        return {
            state       : false,
            visualState : false,
            isNumeric   : false,
            numericState: 0
        }
    },
    created()
    {

        let state = this.$props.device.internals.state

        if( 'off' !== state && 'on' !== state )
        {
            this.state = 'on'
            this.visualState = 'on'
            this.numericState = parseInt( state )
            this.isNumeric = true
        }
        else
        {
            this.isNumeric = false
            this.numericState = 0
            this.state = this.$props.device.stateInversion ?
                         ( 'off' === this.$props.device.internals.state ? 'on' : 'off' ) : this.$props.device.internals.state
            this.visualState = this.state
        }

        console.log( this.$props.device.name, this.$props.device.internals.state, this.$props.device.stateInversion, this.visualState )

    },
    methods: {
        handle( direction )
        {

            let state       = direction === 'up' ? 'on' : 'off',
                newState    = true === this.$props.device.stateInversion ? 'off' === state ? 'on' : 'off' : state,
                visualState = state,
                command     = 'set ' + this.$props.device.name + ' ' + newState

            this.$core
                .getClient()
                .command( command )

            this.visualState = visualState

        }
    }
}
</script>