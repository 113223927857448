function Event( name )
{
    this.name = name
    this.callbacks = []
    this.multis = {}
}

function IndexedEvent( name )
{
    this.name = name
    this.callbacks = {}
    this.multis = {}
}

function NamedEvent( name )
{
    this.name = name
    this.callbacks = []
}

Event.prototype.registerCallback = function( callback )
{
    this.callbacks.push( callback )
}

NamedEvent.prototype.registerCallback = function( callback )
{
    this.callbacks.push( callback )
}

IndexedEvent.prototype.registerCallback = function( index, callback )
{
    this.callbacks[ index ] = callback
}

IndexedEvent.prototype.unregisterCallback = function( index )
{
    delete this.callbacks[ index ]
}

function Reactor()
{
    this.events = {}
    this.namedEvents = {}
    this.indexedEvents = {}
}

Reactor.prototype.registerEvent = function( eventName )
{
    this.events[ eventName ] = new Event( eventName )

}

Reactor.prototype.registerIndexedEvent = function( eventName )
{
    this.indexedEvents[ eventName ] = new IndexedEvent( eventName )
}

Reactor.prototype.registerNamedEvent = function( eventName, name )
{
    if( undefined === this.namedEvents[ eventName ] )
    {
        this.namedEvents[ eventName ] = {}
    }
    if( undefined === this.namedEvents[ eventName ][ name ] )
    {
        this.namedEvents[ eventName ][ name ] = {}
    }
    this.namedEvents[ eventName ][ name ] = new NamedEvent( eventName )
}

Reactor.prototype.unregisterEvent = function( eventName )
{
    if( undefined !== this.events[ eventName ] )
    {
        delete ( this.events[ eventName ] )
    }
}

Reactor.prototype.unregisterIndexedCallback = function( eventName, index )
{
    if( undefined !== this.indexedEvents[ eventName ] )
    {
        this.indexedEvents[ eventName ].unregisterCallback( index )
        if( 0 === Object.keys( this.indexedEvents[ eventName ].callbacks ).length )
        {
            this.unregisterIndexedEvent( eventName )
        }
    }
}

Reactor.prototype.unregisterIndexedEvent = function( eventName )
{
    delete ( this.indexedEvents[ eventName ] )
}

Reactor.prototype.unregisterNamedEvent = function( eventName, name )
{
    if( undefined !== this.namedEvents[ eventName ] )
    {
        delete ( this.namedEvents[ eventName ][ name ] )
    }
}

Reactor.prototype.dispatchEvent = function( eventName, eventArgs )
{
    this.events[ eventName ].callbacks.forEach( function( callback )
    {
        callback( eventArgs )
    } )
}

Reactor.prototype.dispatchIndexedEvent = function( eventName, eventArgs )
{
    let keys = Object.keys( this.indexedEvents[ eventName ].callbacks )

    for( let k in keys )
    {
        let index = keys[ k ]
        this.indexedEvents[ eventName ].callbacks[ index ]( eventArgs )
    }
}

Reactor.prototype.dispatchNamedEvent = function( eventName, eventArgs )
{

    for( const name of Object.keys( this.namedEvents[ eventName ] ) )
    {

        this.namedEvents[ eventName ][ name ].callbacks.forEach( function( callback )
        {
            callback( eventArgs )
        } )
    }

}

Reactor.prototype.isRegistered = function( eventName )
{

    return undefined !== this.events[ eventName ]

}

Reactor.prototype.isIndexedEventRegistered = function( eventName )
{

    return undefined !== this.indexedEvents[ eventName ]

}

Reactor.prototype.isNamedEventRegistered = function( eventName, name )
{

    if( undefined !== name )
    {
        return undefined !== this.namedEvents[ eventName ]
               && undefined !== this.namedEvents[ eventName ][ name ]
    }

    return undefined !== this.namedEvents[ eventName ]

}

Reactor.prototype.addEventListener = function( eventName, callback )
{
    this.events[ eventName ].registerCallback( callback )
}

Reactor.prototype.addIndexedEventListener = function( eventName, index, callback )
{
    this.indexedEvents[ eventName ].registerCallback( index, callback )
}

Reactor.prototype.addNamedEventListener = function( eventName, name, callback )
{
    this.namedEvents[ eventName ][ name ].registerCallback( callback )
}

export default class EventManager
{

    constructor( core )
    {

        if( !EventManager.instance )
        {

            this.core = core
            this.logger = core.getLogger()
            this.config = core.getConfig()
            this.uuid = core.getUuid()

            this.logger.cconstructed( 'EventManager:constructor', 'eventManager initializing...' )
            this.reactor = new Reactor()

            this.lateAckStack = {}
            this.lateAckTimeout = 10000

            EventManager.instance = this

            this.add( 'on-core-timer-ready', () =>
            {

                this.coreTimer = this.core.getCoreTimer()

                this.coreTimer.addInterval( 'timed-late-acks', 1000, () =>
                {
                    this.checkLateAckStack()
                } )
                this.coreTimer.addInterval( 'timed-event-stats', 10000, () =>
                {
                    this.logger.cdebug( 'EventManager.EventStatistics >>', 'bare: ', Object.keys( this.reactor.events ).length, 'named: ', Object.keys( this.reactor.namedEvents ).length, 'indexed: ', Object.keys( this.reactor.indexedEvents ).length )
                } )

            } )

        }

        return EventManager.instance

    }

    destruct()
    {

        this.coreTimer.removeInterval( 'timed-event-stats' )
        this.coreTimer.removeInterval( 'timed-late-acks' )
        delete this.lateAckStack
        delete this.reactor
        delete EventManager.instance

    }

    add( eventName, callback )
    {

        this.reactor.registerEvent( eventName )
        this.reactor.addEventListener( eventName, callback )
        if( this.reactor.isRegistered( 'EM-on-registered-' + eventName ) )
        {
            this.dispatchAndRemove( 'EM-on-registered-' + eventName )
        }

    }

    append( eventName, callback )
    {

        if( !this.reactor.isRegistered( eventName ) )
        {
            this.reactor.registerEvent( eventName )
        }
        this.reactor.addEventListener( eventName, callback )

    }

    appendNamed( eventName, name, callback )
    {

        if( !this.reactor.isNamedEventRegistered( eventName, name ) )
        {
            this.reactor.registerNamedEvent( eventName, name )
        }

        this.reactor.addNamedEventListener( eventName, name, callback )

        if( this.reactor.isRegistered( 'EM-on-named-registered-' + eventName ) )
        {
            this.dispatchAndRemove( 'EM-on-named-registered-' + eventName )
        }

    }

    addOnce( eventName, callback )
    {
        if( !this.isRegistered( eventName ) )
        {
            this.add( eventName, callback )
        }
    }

    replace( eventName, callback )
    {

        if( this.isRegistered( eventName ) )
        {
            this.remove( eventName )
        }

        this.add( eventName, callback )

    }

    remove( eventName )
    {

        if( this.isRegistered( eventName ) )
        {
            this.reactor.unregisterEvent( eventName )
        }

    }

    removeNamed( eventName, name )
    {

        if( this.reactor.isNamedEventRegistered( eventName ) )
        {
            this.reactor.unregisterNamedEvent( eventName, name )
        }

    }

    removeAll( eventName )
    {
        if( this.isRegistered( eventName ) )
        {
            this.reactor.unregisterEvent( eventName )
        }
    }

    dispatch( eventName, args )
    {

        if( !this.reactor )
        {
            return
        }

        if( this.isRegistered( eventName ) )
        {
            this.reactor.dispatchEvent( eventName, args )
        }
        else
        {
            let emName = 'EM-on-registered-' + eventName
            this.lateAckStack[ emName ] = Date.now()
            this.add( emName, () =>
            {
                this.reactor.dispatchEvent( eventName, args )
            } )
        }

    }

    dispatchDirect( eventName, args )
    {
        if( !this.reactor )
        {
            return
        }

        if( this.isRegistered( eventName ) )
        {
            this.reactor.dispatchEvent( eventName, args )
        }

    }

    dispatchNamed( eventName, args )
    {

        if( !this.reactor )
        {
            return
        }

        if( this.reactor.isNamedEventRegistered( eventName ) )
        {
            this.reactor.dispatchNamedEvent( eventName, args )
        }
        else
        {
            let emName = 'EM-on-named-registered-' + eventName
            this.lateAckStack[ emName ] = Date.now()
            this.add( emName, () =>
            {
                this.reactor.dispatchNamedEvent( eventName, args )
            } )
        }

    }

    dispatchAndRemove( eventName, args )
    {

        if( !this.reactor )
        {
            return
        }

        if( this.isRegistered( eventName ) )
        {
            this.reactor.dispatchEvent( eventName, args )
            this.reactor.unregisterEvent( eventName )
            this.reactor.unregisterEvent( 'EM-on-registered-' + eventName )
        }
        else
        {
            let emName = 'EM-on-registered-' + eventName
            this.lateAckStack[ emName ] = Date.now()
            this.add( emName, () =>
            {
                this.dispatchAndRemove( eventName, args )
            } )
        }

    }

    dispatchAndRemoveNamed( eventName, args )
    {

        this.dispatchNamed( eventName, args, true )

    }

    isRegistered( eventName )
    {

        if( !this.reactor )
        {
            return
        }

        return this.reactor.isRegistered( eventName )

    }

    removeIndexedCallback( eventName, index )
    {
        this.reactor.unregisterIndexedCallback( eventName, index )
    }

    dispatchIndexed( eventName, args )
    {
        if( this.reactor.isIndexedEventRegistered( eventName ) )
        {
            this.reactor.dispatchIndexedEvent( eventName, args )
        }
    }

    /*eslint-disable*/
    removeIndexedByNameWildcard( eventName )
    {
        let droplist = []
        for( let event in this.reactor.indexedEvents )
        {
            if( -1 < event.indexOf( eventName ) )
            {
                droplist.push( event )
            }
        }
        droplist.forEach( ( name ) =>
        {
            delete this.reactor.indexedEvents[ name ]
        } )
    }

    dispatchAndRemoveIndexed( eventName, args )
    {
        this.reactor.dispatchIndexedEvent( eventName, args )
        this.reactor.unregisterIndexedEvent( eventName, args )
    }

    addIndexed( eventName, callback )
    {

        let index = this.uuid.generate()
        if( !this.reactor.isIndexedEventRegistered( eventName ) )
        {
            this.reactor.registerIndexedEvent( eventName )
        }
        this.reactor.addIndexedEventListener( eventName, index, callback )

        return index

    }

    checkLateAckStack()
    {

        let checkTsmp = ( Date.now() - this.lateAckTimeout )

        for( let e in this.lateAckStack )
        {

            let eventName = e,
                tsmp      = this.lateAckStack[ e ]

            if( tsmp < checkTsmp )
            {
                if( this.reactor.isRegistered( eventName ) )
                {
                    this.reactor.unregisterEvent( eventName )
                    this.reactor.unregisterNamedEvent( eventName )
                }
                delete ( this.lateAckStack[ eventName ] )
            }

        }

    }

}