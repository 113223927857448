import { openDB } from 'idb'

export default class Database
{

    constructor( core )
    {

        if( !Database.instance )
        {

            this.logger = core.getLogger()
            this.uuid = core.getUuid()
            this.eventManager = core.getEventManager()

            this.setState = ( key, value ) =>
            {
                return core.setState( key, value )
            }

            this.databaseName = 'customsmarthome'
            this.databaseVersion = 9
            this.logger.cconstructed( 'Database:constructor', 'initializing database "' + this.databaseName + '", version ' + this.databaseVersion )

            this.db = this.openDatabase()

            Database.instance = this

        }

        return Database.instance

    }

    /**
     * openDatabase
     * - opens indexeddb connection and returns database promise
     * @param dropflag (boolean, drop database or not)
     * @returns {Promise<IDBPDatabase<unknown>>}
     */
    openDatabase( dropflag )
    {

        let fresh = false
        let dbPromise = openDB( this.databaseName, this.databaseVersion, {
            upgrade( db, oldVersion, newVersion, upgradeDB )
            {

                fresh = true

                switch( oldVersion )
                {
                    case 0:
                        upgradeDB.db.createObjectStore( 'storage' )
                    // falls through
                    case 1:
                        upgradeDB.db.createObjectStore( 'settings' )
                    // falls through
                    case 2:
                        upgradeDB.db.createObjectStore( 'devices' )
                    // falls through
                    case 3:
                        upgradeDB.db.createObjectStore( 'rooms' )
                    // falls through
                    case 4:
                        upgradeDB.db.createObjectStore( 'topics' )
                    // falls through
                    case 5:
                        upgradeDB.db.createObjectStore( 'categories' )
                    // falls through
                    case 6:
                        upgradeDB.db.createObjectStore( 'scripts' )
                    // falls through
                    case 7:
                        upgradeDB.db.createObjectStore( 'cameras' )
                    // falls through
                    case 8:
                        upgradeDB.db.createObjectStore( 'users' )
                    // falls through
                }

            }
        } ).then( ( db ) =>
        {

            if( undefined !== dropflag )
            {

                this.logger.log( 'database dropflag is set: will destroy objectstores' )

                db.close()

                setTimeout( () =>
                {

                    let req = indexedDB.deleteDatabase( this.databaseName )
                    req.onsuccess = () =>
                    {
                        this.logger.log( 'database dropped' )
                    }

                }, 10 )

            }
            return db

        } )

        return dbPromise

    }

    /**
     * readStorage
     * @param key
     * @returns {Promise<any>}
     */
    readStorage( key )
    {
        return new Promise(
            ( resolve, reject ) =>
            {
                return this.db
                           .then( async db =>
                           {
                               db.transaction( 'storage' )
                                 .objectStore( 'storage' ).get( key )
                                 .then( ( obj ) =>
                                 {

                                     if( undefined !== obj )
                                     {
                                         return resolve( obj )
                                     }

                                     return reject( 'ERROR_NOT_FOUND FOR ' + key )

                                 } )
                           } )
            } )
    }

    /**
     * writeStorage
     * @param key
     * @param value
     * @returns {Promise<any>}
     */
    writeStorage( key, value )
    {

        return new Promise(
            ( resolve, reject ) =>
            {
                this.db
                    .then( db =>
                    {

                        const transaction = db.transaction( 'storage', 'readwrite' )

                        if( key === undefined )
                        {
                            return reject( 'ERROR_KEY_MISSING' )
                        }

                        transaction.objectStore( 'storage' ).put( value, key )
                                   .finally( success =>
                                   {
                                       return resolve( success )
                                   } )

                    } )
                    .catch( error =>
                    {

                        return reject( 'DATABASE ERROR: ' + error )

                    } )
            } )
    }


    /**
     * _getResultsRecursive
     * @param cursor
     * @param list
     * @returns {Promise<any>}
     * @private
     */
    _getResultsRecursive( cursor, list )
    {

        return new Promise(
            ( resolve ) =>
            {

                if( cursor )
                {

                    list.push( {
                        key : cursor.key,
                        item: cursor.value
                    } )

                    cursor.continue()
                          .then( result =>
                          {

                              return resolve( this._getResultsRecursive( result, list ) )

                          } )

                }
                else
                {

                    return resolve( list )

                }

            } )

    }

    /**
     * readAll
     * @param storage
     * @returns {Promise<any>}
     */
    readAll( storage )
    {

        return new Promise(
            ( resolve, reject ) =>
            {

                this.db
                    .then( async db =>
                    {

                        let transaction = db.transaction( storage, IDBTransaction.READ_ONLY ),
                            store       = transaction.objectStore( storage ),
                            items       = []

                        store.openCursor()
                             .then( cursor =>
                             {

                                 this._getResultsRecursive( cursor, items )
                                     .then( result =>
                                     {

                                         resolve( result )

                                     } )
                             } )

                    } )
                    .catch( error =>
                    {
                        return reject( error )
                    } )

            } )

    }

    /**
     * readDevices
     * @returns {Promise<any>}
     */
    readDevices()
    {

        return this.readAll( 'devices' )

    }

    writeList( storage, list )
    {

        return new Promise( ( resolve, reject ) =>
        {

            this.db.then( database =>
            {

                const transaction = database.transaction( storage, 'readwrite' )
                for( let l in list )
                {

                    transaction.objectStore( storage ).put( list[ l ].item, list[ l ].key )

                }

                transaction.done.then( () =>
                {
                    return resolve()
                } )

                transaction.onerror = ( e ) =>
                {
                    return reject( e )
                }

            } )

        } )

    }

    write( storeName, key, value )
    {

        return new Promise( ( resolve, reject ) =>
        {

            this.db.then( database =>
                {

                    const transaction = database.transaction( storeName, 'readwrite' )

                    if( key === undefined )
                    {
                        return reject( 'ERROR_KEY_MISSING' )
                    }
                    else
                    {

                        let clone = JSON.parse( JSON.stringify( value ) )
                        transaction.objectStore( storeName ).put( clone, key )
                                   .finally( success =>
                                   {
                                       return resolve( success )
                                   } )
                                   .catch( () =>
                                   {
                                       return reject( 'ERROR WRITE DB' )
                                   } )
                    }

                } )
                .catch( error =>
                {
                    return reject( 'ERROR_DB_OPEN:' + error )
                } )

        } )

    }

    clear( storeName )
    {

        return new Promise( ( resolve, reject ) =>
        {

            this.db.then( database =>
                {

                    const transaction = database.transaction( storeName, 'readwrite' )

                    transaction.objectStore( storeName ).clear()
                               .catch( () => {
                                   return reject( 'ERROR_DB_CLEAR:' + error )
                               })
                               .finally( success =>
                               {
                                   return resolve( success )
                               } )

                } )
                .catch( error =>
                {
                    return reject( 'ERROR_DB_CLEAR_TRANSACTION:' + error )
                } )

        } )


    }

    /**
     * delete
     * @param storage
     * @param key
     * @returns {Promise<DB | never>}
     */
    delete( storage, key )
    {
        return new Promise( ( resolve, reject ) =>
        {

            this.db.then( database =>
                {

                    const transaction = database.transaction( storage, 'readwrite' )
                    transaction.objectStore( storage ).delete( key )
                    return resolve()

                } )
                .catch( () =>
                {
                    return reject()
                } )

        } )
    }

}